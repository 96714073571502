import { ConvertLocale } from "../utils/ConvertLocale";
import { clearToken, getAccessToken } from "../utils/AccessToken";
import {ApiAxios, axiosSetting} from "@modules/client/ApiAxios";

/**
 * 마이 페이지 - 메인
 * */
export async function getMyPageMain(locale) {
  const res = await fetch(`/api/crew/main`, {
    headers: {
      Authorization: getAccessToken(),
      "Accept-Language": ConvertLocale(locale),
    },
  });
  if (res.status === 401) {
    clearToken();
    location.reload();
  }
  return { status: res.status, body: await res.json() };
}
/**
 * 마이 - 메인 상단 정보
 * */
export async function getCrewMainSimple(locale) {
  const res = await fetch(`/api/crew/main/simple`, {
    headers: {
      Authorization: getAccessToken(),
      "Accept-Language": ConvertLocale(locale),
    },
  });
  if (res.status === 401) {
    clearToken();
    location.reload();
  }
  return { status: res.status, body: await res.json() };
}
/**
 * 마이 - 내 프로필 조회
 * */
export async function getMyProfile(locale) {
  const res = await fetch(`/api/crew/profile`, {
    headers: {
      Authorization: getAccessToken(),
      "Accept-Language": ConvertLocale(locale),
    },
  });
  if (res.status === 401) {
    clearToken();
    location.reload();
  }
  return { status: res.status, body: await res.json() };
}
/**
 * 마이 - 내 프로필 수정
 * */
export async function updateMyProfile(locale, body) {
  const res = await fetch(`/api/crew/profileUpdate`, {
    method: "PUT",
    headers: {
      Authorization: getAccessToken(),
      "Accept-Language": ConvertLocale(locale),
    },
    body: body,
  });
  if (res.status === 401) {
    clearToken();
    location.reload();
  }
  return { status: res.status, body: await res.json() };
}
export async function bookmarkAdd(locale, body) {
  const res = await fetch(`/api/bookmarkAdd`, {
    method: "POST",
    headers: {
      Authorization: getAccessToken(),
      "Accept-Language": ConvertLocale(locale),
    },
    body: JSON.stringify(body),
  });
  if (res.status === 401) {
    clearToken();
    location.reload();
  }
  return { status: res.status, body: await res.json() };
}
export async function bookmarkDel(locale, type, id) {
  const res = await fetch(`/api/bookmarkDel?type=${type}&id=${id}`, {
    method: "DELETE",
    headers: {
      Authorization: getAccessToken(),
      "Accept-Language": ConvertLocale(locale),
    },
  });
  if (res.status === 401) {
    clearToken();
    location.reload();
  }
  return { status: res.status, body: await res.json() };
}

export async function getPushAgreement(locale) {
  const res = await fetch(`/api/www/crew/get_ad_agreement`, {
    headers: {
      Authorization: getAccessToken(),
      "Accept-Language": ConvertLocale(locale),
    },
  });
  return { status: res.status, body: await res.json() };
}

export async function updatePushAgreement(locale, body) {
  const res = await fetch(`/api/www/crew/post_ad_agreement`, {
    method: "POST",
    headers: {
      Authorization: getAccessToken(),
      "Accept-Language": ConvertLocale(locale),
    },
    body: JSON.stringify(body),
  });
  if (res.status === 401) {
    clearToken();
    location.reload();
  }
  return { status: res.status, body: await res.json() };
}

export async function updatePassword(locale, body) {
  const res = await fetch(`/api/crew/password`, {
    method: "POST",
    headers: {
      Authorization: getAccessToken(),
      "Accept-Language": ConvertLocale(locale),
    },
    body: JSON.stringify(body),
  });
  if (res.status === 401) {
    clearToken();
    location.reload();
  }
  return { status: res.status, body: await res.json() };
}

export async function getCoupon(locale, page, size) {
  const res = await fetch(`/api/mypage/coupon?page=${page}&size=${size}`, {
    headers: {
      Authorization: getAccessToken(),
      "Accept-Language": ConvertLocale(locale),
    },
  });
  if (res.status === 401) {
    clearToken();
    location.reload();
  }
  return { status: res.status, body: await res.json() };
}
export async function getCouponHistory(locale, page, size) {
  const res = await fetch(
    `/api/mypage/couponHistory?page=${page}&size=${size}`,
    {
      headers: {
        Authorization: getAccessToken(),
        "Accept-Language": ConvertLocale(locale),
      },
    }
  );
  if (res.status === 401) {
    clearToken();
    location.reload();
  }
  return { status: res.status, body: await res.json() };
}
export async function addCoupon(locale, code) {
  const res = await fetch(`/api/mypage/couponAdd?code=${code}`, {
    method: "POST",
    headers: {
      Authorization: getAccessToken(),
      "Accept-Language": ConvertLocale(locale),
    },
  });
  if (res.status === 401) {
    clearToken();
    location.reload();
  }
  return { status: res.status, body: await res.json() };
}

export async function bookings(locale, status, page, size) {
  const res = await fetch(
    `/api/crew/booking/${status}?page=${page}&size=${size}`,
    {
      headers: {
        Authorization: getAccessToken(),
        "Accept-Language": ConvertLocale(locale),
      },
    }
  );
  if (res.status === 401) {
    clearToken();
    location.reload();
  }
  return { status: res.status, body: await res.json() };
}

export async function bookingDetail(locale, bookingId) {
  const res = await fetch(`/api/crew/booking/detail/${bookingId}`, {
    headers: {
      Authorization: getAccessToken(),
      "Accept-Language": ConvertLocale(locale),
    },
  });
  return { status: res.status, body: await res.json() };
}

/**
 * 마이 - 예약 영수증 조회
 * */
export async function getReceipt(locale, paymentId) {
  const res = await fetch(`/api/crew/booking/receipt/${paymentId}`, {
    headers: {
      Authorization: getAccessToken(),
      "Accept-Language": ConvertLocale(locale),
    },
  });
  return { status: res.status, body: await res.json() };
}

/**
 * 마이 - 바우처
 * */
export async function getVoucher(locale, bookingId) {
  const res = await fetch(`/api/crew/booking/voucher/${bookingId}`, {
    headers: {
      Authorization: getAccessToken(),
      "Accept-Language": ConvertLocale(locale),
    },
  });
  return { status: res.status, body: await res.json() };
}

export async function getCards(locale) {
  const res = await fetch(`/api/crew/cards`, {
    headers: {
      Authorization: getAccessToken(),
      "Accept-Language": ConvertLocale(locale),
    },
  });
  if (res.status === 401) {
    clearToken();
    location.reload();
  }
  return { status: res.status, body: await res.json() };
}

export async function withdraw_check(locale) {
  const res = await fetch(`/api/crew/withdraw_check`, {
    headers: {
      Authorization: getAccessToken(),
      "Accept-Language": ConvertLocale(locale),
    },
  });
  if (res.status === 401) {
    clearToken();
    location.reload();
  }
  return { status: res.status, body: await res.json() };
}

export async function comeback_reward(locale) {
  const res = await fetch(`/api/crew/withdrawal/comeback_reward`, {
    method: "PUT",
    headers: {
      Authorization: getAccessToken(),
      "Accept-Language": ConvertLocale(locale),
    },
  });
  if (res.status === 401) {
    clearToken();
    location.reload();
  }
  return { status: res.status, body: await res.json() };
}

export async function withdrawalExtinction(locale) {
  const res = await fetch(`/api/crew/withdrawal/extinction`, {
    headers: {
      Authorization: getAccessToken(),
      "Accept-Language": ConvertLocale(locale),
    },
  });
  if (res.status === 401) {
    clearToken();
    location.reload();
  }
  return { status: res.status, body: await res.json() };
}

export async function triptalkReply(locale, page, size) {
  const res = await fetch(
    `/api/crew/triptalk/reply?page=${page}&size=${size}`,
    {
      headers: {
        Authorization: getAccessToken(),
        "Accept-Language": ConvertLocale(locale),
      },
    }
  );
  return { status: res.status, body: await res.json() };
}

export async function withdrawal(locale, body) {
  const res = await fetch(`/api/crew/withdrawal`, {
    method: "delete",
    headers: {
      Authorization: getAccessToken(),
      "Accept-Language": ConvertLocale(locale),
    },
    body: JSON.stringify(body),
  });
  if (res.status === 401) {
    clearToken();
    location.reload();
  }
  return { status: res.status, body: await res.json() };
}

export async function bookingGuest(locale, body) {
  const res = await fetch(`/api/crew/booking/detail/guest`, {
    method: "POST",
    headers: {
      Authorization: getAccessToken(),
      "Accept-Language": ConvertLocale(locale),
    },
    body: JSON.stringify(body),
  });
  return { status: res.status, body: await res.json() };
}
/**
 * 마이 페이지 - 결제 카드 관리 조회
 * */
export async function getCard(locale) {
  const res = await fetch(`/api/crew/cards`, {
    headers: {
      Authorization: getAccessToken(),
      "Accept-Language": ConvertLocale(locale),
    },
  });
  if (res.status === 401) {
    clearToken();
    location.reload();
  }
  return { status: res.status, body: await res.json() };
}
/**
 * 마이 페이지 - 결제 카드 관리 삭제
 * */
export async function deleteCard(locale, cardIndex) {
  const res = await fetch(`/api/crew/cards/${cardIndex}`, {
    method: "DELETE",
    headers: {
      Authorization: getAccessToken(),
      "Accept-Language": ConvertLocale(locale),
    },
  });
  if (res.status === 401) {
    clearToken();
    location.reload();
  }
  return { status: res.status, body: await res.json() };
}
/**
 * 마이 - 결제 카드 이미지 조회
 * */
export async function getCardImage(locale) {
  const res = await fetch(`/api/crew/cards/image`, {
    method: "GET",
    headers: {
      "Accept-Language": ConvertLocale(locale),
    },
  });
  return { status: res.status, body: await res.json() };
}

/**
 * middleware get user_id
 * */

export const getUser = async (locale, auth) => {
   const res = await fetch(axiosSetting.server() + '/crew/profile', {
     method:'GET',
     headers : {
       'Authorization' : auth,
       'Accept-Language' : locale,
       "Content-Type": "application/json",
       "x-tripbtoz-channel": "KBPAY",
       "X-UA-Timezone": Intl.DateTimeFormat().resolvedOptions().timeZone,
     }
   }).then( _ => _.json())

  if(res.code === 'SUCCESS') return res.item
  else return res

}
