import { isIOS } from "react-device-detect";

export function enableBodyScrollLock() {
  const top = window.scrollY;
  document.body.style.overflow = "hidden";
  if (isIOS) {
    document.body.style.position = "fixed";
    document.body.style.width = "100%";
    document.body.style.top = -top + "px";
  }
}
export function disableBodyScrollLock() {
  if (isIOS) {
    const top = document.body.style.top;
    document.body.style = undefined;
    window.scrollTo(0, Math.abs(Number(top.replace("px", ""))));
  } else {
    document.body.style = undefined;
  }
}
