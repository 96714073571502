export const Contains = {
  MAIN: "MAIN",
  HOTEL: "HOTEL",
  REGION: "REGION",
  SEARCH: "SEARCH",
  SEARCH_MOBILE: "SEARCH_MOBILE",
  LOGIN: "LOGIN",
  SIGNUP: "SIGNUP",
  MYPAGE_MAIN: "MYPAGE_MAIN",
  MYPAGE: "MYPAGE",
  PROFILE: "PROFILE",
  CHECKOUT: "CHECKOUT",
  WITHDRRAW: "WITHDRRAW",
  MD: "MD",
  VERIFY: 'VERIFY',
  CITY_STORY: "CITY_STORY",

  APP:"APP",
  WEB:"WEB",

  GOOGLE_MAP_API_KEY: "AIzaSyBPIR09kU1fNbpTEjkzWouX5kh3VpAGWgg",

  KRW: "원",
  USD: "$",

  EMAIL_PATTEN:
    /^[0-9a-zA-Z-_.!#$%&'*/=?^`{|}~]+@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i,

  PASSWORD_PATTEN:
    /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d!@#$%^&*()\[\]{}\-=_+~`;':"<>?/.,|\\]{8,20}$/,
};
